<template>
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="email"
                label="Login"
                name="login"
                prepend-icon="mdi-account"
                type="email"
              ></v-text-field>

              <v-text-field
                v-model="password"
                label="Password"
                name="password"
                type="password"
                prepend-icon="mdi-lock"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="isLoading" color="primary" @click="login()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Error Snackbar -->
    <v-snackbar v-model="showError" color="error" :timeout="-1">
      Invalid Email or Password
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showError = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      showError: false,
      error: '',
    };
  },

  methods: {
    async login() {
      this.isLoading = true;
      try {
        const resp = await axios.post(`${process.env.VUE_APP_API_URL}/users/login`, {
          request: {
            email: this.email,
            password: this.password,
          },
        });

        // save loggedin user
        localStorage.setItem('currentUser', JSON.stringify(resp.data));
        await this.$router.go(0);
      } catch (e) {
        this.error = e.response.data.message;
        this.showError = true;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    const user = localStorage.getItem('currentUser');
    if (user !== null) {
      this.$router.push('/');
    }
  },
};
</script>

<style></style>
